import { Dropdown } from "antd";
import { type FC, type ReactElement } from "react";
import { Avatar, TopNavBar } from "syngenta-digital-cropwise-react-ui-kit";
import { useAuth } from "../../lib/auth/AuthContext";
import "./header.less";
import { type profile } from "./types";
import Logo from "../../assets/images/logo.svg";

const getInitials = (name: string): string => {
  try {
    const firstName = name.split(" ")[0];
    const lastName = name.length > 0 ? name.split(" ")[1] : " ";
    return `${firstName.split("")[0]}${lastName.split("")[0]}`.toUpperCase();
  } catch {
    return "NA";
  }
};

const ProfileOverlay = (): JSX.Element => {
  const { user, logout, setLogoutStatus } = useAuth();
  return (
    <div data-cy="profileOverlay" className="logoutOverlayBlock">
      <div className="profileInfo">
        <h3>{"User Profile"}</h3>
        <div className="profileUserData">
          <span>{user?.name}</span>
        </div>
      </div>
      <div
        className="logoutLink"
        onClick={() => {
          setLogoutStatus(true);
          void logout();
        }}
        onKeyDown={() => {
          setLogoutStatus(true);
          void logout();
        }}
        role="menu"
        tabIndex={0}
      >
        <span>{"Log out"}</span>
      </div>
    </div>
  );
};

const Profile: FC<profile> = ({ initials }) => {
  return (
    <Dropdown
      trigger={["click"]}
      overlay={<ProfileOverlay />}
      placement="topRight"
    >
      <a
        data-cy="profile-icon"
        className="ant-dropdown-link"
        onClick={(e) => {
          e.preventDefault();
        }}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        role="menuitem"
        tabIndex={0}
      >
        <Avatar shape="circle" size={40}>
          {initials}
        </Avatar>
      </a>
    </Dropdown>
  );
};

function getNavBarIcon(): ReactElement {
  return (
    <div data-cy="navBarIcon" style={{ height: "34px", width: "34px" }}>
      <img src={Logo} alt="Cropwise Sustainability" />
    </div>
  );
}

export const GlobalHeader: FC = (): ReactElement => {
  const { user } = useAuth();
  return (
    <>
      <TopNavBar
        navbarIcon={getNavBarIcon}
        newDesign={true}
        profileDropDown={<Profile initials={getInitials(user?.name)} />}
      />
    </>
  );
};
