import { useContext, type ReactElement } from "react";
import { Outlet, useParams } from "react-router-dom";
import { PartnerDetailsContext } from "..";
import useAxios from "../../shared/custom-hooks/useAxios";
import { TopBreadcrumb } from "../../shared/layout/Breadcrumb";
import { ContentLoader } from "../../shared/layout/ContentLoader";
import {
  type PartnerDetails,
  type ReportDetails,
} from "../reports-overview-and-navigation/types";
import { TopNavigationTabs } from "./TopNavigationTabs";

export const Reports = (): ReactElement => {
  const { partnerId, cropYear } = useParams();
  const {
    partner,
    reportsByPartner,
    setPartner,
    setReportsByPartner,
    setReport,
    report,
  } = useContext(PartnerDetailsContext);
  let reports = reportsByPartner;
  let partnerName = partner;
  let reportName = report;
  const { response } = useAxios(`/report/list/${partnerId ?? ""}`);
  const { response: partners } = useAxios("/partner");

  // if user refreshes page, below logic will execute to get data from api
  if (!reportsByPartner || !partner || !reportName) {
    if (partners && response) {
      partnerName = partners?.find(
        (partner: PartnerDetails) => partner.id === partnerId
      ).name;
      setPartner(partnerName);
      reports = response?.reports;
      setReportsByPartner(reports);
      reportName =
        reports?.find((report: ReportDetails) => report.cropYear === cropYear)
          ?.name ?? "";
      setReport(reportName);
    }
  }

  return (
    <TopBreadcrumb moduleTitle={reportName} partnerName={partnerName}>
      <ContentLoader>
        <TopNavigationTabs>
          <Outlet />
        </TopNavigationTabs>
      </ContentLoader>
    </TopBreadcrumb>
  );
};
