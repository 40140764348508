import React, { useContext, useEffect, useState, type MouseEvent } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Row,
  Typography,
} from "syngenta-digital-cropwise-react-ui-kit";
import { PartnerDetailsContext } from "..";
import RightArrow from "../../assets/images/chevron_right.svg";
import FactCheck from "../../assets/images/fact-check.svg";
import OpenInNew from "../../assets/images/open-in-new.svg";
import { LeftNavigator } from "../../shared/components/LeftNavigationBar";
import {
  InlineErrorMessage,
  InlineLoader,
} from "../../shared/components/Loader";
import { useAmplitudeLogging } from "../../shared/custom-hooks/useAmplitudeTracking";
import useAxios from "../../shared/custom-hooks/useAxios";
import { usePrevious } from "../../shared/custom-hooks/usePrevious";
import { TopBreadcrumb } from "../../shared/layout/Breadcrumb";
import { ContentLoader } from "../../shared/layout/ContentLoader";
import AxiosInstance from "../../shared/utils/axios";
import {
  getElementTargetID,
  getInitials,
} from "../../shared/utils/common-utils";
import "../style.less";
import SubmittedAssesments from "./SubmittedAssesments";
import "./style.less";
import { type PartnerDetails, type ReportDetails } from "./types";

export default function ReportsNavigationAndOverview(props: any): JSX.Element {
  const { state } = useLocation();
  const {
    setPartner,
    setReportsByPartner,
    reportsByPartner,
    partner,
    setReport,
  } = useContext(PartnerDetailsContext);
  const [partnerId, setPartnerId] = useState<string>(state?.partnerId ?? "");
  useEffect(() => {
    if (partnerId) {
      void getReportsByPartner(partnerId);
    }
  }, []);

  const { loading, response: partners } = useAxios("/partner");

  const toggleAssessmentModal = (
    event: MouseEvent<HTMLElement> | any
  ): void => {
    const reportName = getElementTargetID(event);
    const updatedReports = reportsByPartner?.map((report: ReportDetails) => {
      if (report.name === reportName) {
        report.isModalOpen = !report?.isModalOpen;
      }
      return report;
    });
    setReportsByPartner(updatedReports);
  };

  const getReportsByPartner = async (partnerId: string): Promise<void> => {
    try {
      const reportsByPartner = await AxiosInstance.get(
        `/report/list/${partnerId}`
      );
      setReportsByPartner(reportsByPartner.data.reports);
    } catch (error) {
      console.warn(error);
    }
  };

  const partnerClickEvent = "Partner name click";
  const clickPartnerTrackEvents = useAmplitudeLogging(partnerClickEvent);
  const showReportsByPartner = (event: MouseEvent<HTMLElement>): void => {
    const partnerId = getElementTargetID(event);
    setPartnerId(partnerId);
    const partnerName = event.currentTarget.getAttribute("data-partner-name");
    if (partnerName) {
      setPartner(partnerName);
    }
    void getReportsByPartner(partnerId);
    clickPartnerTrackEvents({
      event: partnerClickEvent,
      "Partner name": partnerName,
    });
  };
  const prevPartnerId = usePrevious(partnerId);

  const eventName = "Open Report";
  const trackEvents = useAmplitudeLogging(eventName);
  const setReportAndLogAmplitudeEvent = (
    event: MouseEvent<HTMLElement>
  ): void => {
    trackEvents({
      event: eventName,
      "Organization name": partner,
      "Report name": event.currentTarget.id,
    });
    setReport(event.currentTarget.id);
  };

  return (
    <TopBreadcrumb>
      <ContentLoader isLoading={false} isError={false}>
        <Row
          className="reports-navigation-and-overview tab-container"
          style={{ display: "flex" }}
        >
          <Col xs={5}>
            <LeftNavigator>
              {loading ? (
                <InlineLoader
                  text="Loading partners..."
                  top="40%"
                  position="relative"
                />
              ) : (
                partners?.map((partner: PartnerDetails, index: number) => {
                  const isActive = partner.id === partnerId ? "active" : "";
                  return (
                    <Card
                      id={partner.id}
                      data-partner-name={partner.name}
                      key={partner.id}
                      tabIndex={index}
                      onClick={showReportsByPartner}
                    >
                      <Row
                        className={`left-navigator-card justify-content__space-between card-border ${isActive}`}
                      >
                        <div
                          id="partner-name-block"
                          className="navigator-title-section justify-content__space-between"
                        >
                          <Typography.Text className="left-navigator__item-text">
                            {partner.name}
                          </Typography.Text>
                          <div
                            className={`icon-background ${isActive} horizantallyCenter`}
                          >
                            <div>{getInitials(partner.name)}</div>
                            {/* <img
                              src={partner.icon}
                              className="left-navigation--tab--start-icon"
                              alt="start-icon"
                            /> */}
                          </div>
                        </div>
                        <img src={RightArrow} alt="right-arrow" />
                      </Row>
                    </Card>
                  );
                })
              )}
            </LeftNavigator>
          </Col>
          <Col xs={19} className="reports">
            <Row style={{ height: "fit-content" }}>
              {partnerId && prevPartnerId !== partnerId ? (
                <InlineLoader
                  text="Loading reports..."
                  left="40%"
                  position="absolute"
                  top="40%"
                />
              ) : partnerId && !reportsByPartner?.length ? (
                <InlineErrorMessage
                  text="No data available..."
                  left="40%"
                  position="absolute"
                  top="40%"
                ></InlineErrorMessage>
              ) : (
                reportsByPartner?.map((report: ReportDetails) => {
                  return (
                    <Col
                      key={report.name}
                      className="report-column s-cards"
                      xs={24}
                      xl={8}
                      lg={12}
                      md={24}
                      sm={24}
                    >
                      <Card className="reports-card">
                        <Row
                          className="justify-content__space-between report-title__row"
                          role="menu"
                        >
                          <h2 className="report__title">{report.name}</h2>
                          <div
                            className="reports__submitted-assesment-icon"
                            onClick={toggleAssessmentModal}
                            onKeyDown={toggleAssessmentModal}
                            id={report.name}
                            role="menuitem"
                            tabIndex={1}
                          >
                            <img src={FactCheck} alt="fact-check" />
                          </div>
                        </Row>
                        {report.isModalOpen ? (
                          <SubmittedAssesments
                            partnerId={partnerId}
                            reportTitle={report.name}
                            cropYear={report.cropYear}
                            onCancel={() => {
                              toggleAssessmentModal({
                                currentTarget: { id: report.name },
                              });
                            }}
                          />
                        ) : null}
                        <Row className="verticallyCenter reports__card-padding">
                          <Typography.Text className="field-label">
                            Status : &nbsp;
                          </Typography.Text>
                          <div
                            className={`report__status-${
                              report.status === "Available" ? "green" : "blue"
                            }`}
                          ></div>
                          <Typography.Text className="field-value">
                            &nbsp; {report.status}
                          </Typography.Text>
                        </Row>
                        {report.status === "Available" && (
                          <Link
                            id={report.name}
                            to={`${String(partnerId)}/${String(
                              report.cropYear
                            )}/overview/context`}
                            onClick={setReportAndLogAmplitudeEvent}
                          >
                            <Button className="reports__open-report">
                              Open Report &nbsp;
                              <img src={OpenInNew} alt="open-icon" />
                            </Button>
                          </Link>
                        )}
                      </Card>
                    </Col>
                  );
                })
              )}
            </Row>
          </Col>
        </Row>
      </ContentLoader>
    </TopBreadcrumb>
  );
}
